import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { GenericFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';

const GenericFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter, value } = props;
  const answerFilters = filter as GenericFilterValue | undefined;
  const { t } = useTranslation('table-view');

  return (
    <div className="flex flex-wrap items-center gap-2">
      {answerFilters?.map((answer, i) => (
        <div key={`${value}-answer-${i}`}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={answer === false ? t('filters.no-data') : (answer as string)} />
        </div>
      ))}
    </div>
  );
};

export default GenericFilterOverview;
